/* Pico tweaks */
:root[data-theme="dark"] {
  --background-color: #1f1a55;
  --card-background-color: #1a1642;
  --muted-border-color: #363089;
  --primary: #ff308e;
  --primary-hover: #d41a6e;
  --red-error: #f03e3e;
  --h1-color: #63ff9a;
  --h2-color: var(--h1-color);
  --font-family: Overpass, sans-serif;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  display: inline-block;
  width: auto;
}

a,
[role=link],
summary,
button,
label {
  font-weight: 600;
  letter-spacing: 0.02em;
}

article {
  padding-block: calc(var(--typography-spacing-vertical) * 1.5) var(--typography-spacing-vertical);
  padding-inline: var(--block-spacing-horizontal);
}

/*****/

h1 {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -0.05em;
  &:not(:has(> svg)) {
    transform: scaleX(1.15);
  }

  --font-size: 2.75rem;
}

hr {
  margin-block: var(--typography-spacing-vertical);
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:is(p, li) > strong {
  color: var(--primary-inverse);
}

@media (min-width: 768px) {
  details {
    padding-bottom: calc(var(--spacing) * 0.8);
  }
}

details > summary {
  --transition: .1s ease-in-out;
  --accordion-open-summary-color: var(--color);

  line-height: var(--line-height);
  font-size: 112%;
}

details > summary:not(:focus) > strong {
  color: var(--primary-inverse);
}

details[open] summary ~ * {
  animation: sweep .4s ease-in-out;
}

@keyframes sweep {
  0%    {opacity: 0; translate: 0px 8px}
  100%  {opacity: 1; translate: 0px 0px}
}

img[perk] {
  border-top-left-radius: 14px 20px;
  border-top-right-radius: 14px 20px;
  border-bottom-left-radius: 14px 20px;
  border-bottom-right-radius: 14px 20px;
  box-shadow: 0px 12px 18px -5px rgba(10, 0, 20, 0.4);

  margin-block-end: var(--spacing);
  will-change: translate;

  transition: all .25s ease-out;
}

img[perk]:hover {
  translate: 0px -4px;
  box-shadow: 0px 18px 17px -8px rgba(10, 0, 20, 0.3);
}

hl-fetch {
  display: block;
}

body > hl-fetch > nav ul {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

body > hl-fetch > nav a {
  text-decoration: none;
}

hl-fetch[fetching] > nav {
  display: none;
}

hl-fetch[type="form"][fetching] {
  opacity: 0.6;
}

form-field {
  display: block;
  padding: .25rem;
  border-radius: 4px;
}

form-field:has([aria-invalid]) {
  outline: 1px dashed rgba(255, 40, 40, 0.5);
}

form-button {
  display: flex;
  justify-content: center;
  margin-block-start: var(--typography-spacing-vertical);
}

p[flash-errors] {
  color: var(--red-error);
  font-weight: bold;
  margin-block-end: calc(0rem - var(--typography-spacing-vertical));
  text-align: center;
}

p[flash-errors]:empty {
  margin-block: 0;
}

/********/

h1[aria-label="Intuitive Future"] > svg {
  width: 124%;
  margin-left: -12%;
}

@media (max-width: 1170px) {
  h1[aria-label="Intuitive Future"] > svg {
    width: 110%;
    margin-left: -5%;
  }
}

@media (max-width: 615px) {
  h1[aria-label="Intuitive Future"] > svg {
    width: 106%;
    margin-left: -3%;
  }
}

h2[aria-label="Intuitive plus"] > svg{
  width: auto;
  height: 85px;
}

@media (max-width: 767px) {
  h2[aria-label="Intuitive plus"] > svg{
    height: 13vw;
  } 
}


perk-item {
  display: block;
  border-inline-start: 5px solid var(--primary);
  padding-inline-start: 1.5rem;
  padding-block: 1rem;
  margin-block: 2rem;

  & form {
    margin-block-end: 0;

    & p:last-of-type {
      margin-block-end: 0;
    }
  }
}